<template>
    <div class="gk-card as-listing-item d-flex flex-column flex-md-row justify-content-md-start align-items-md-center p-4" v-if="campaign">
        <div class="mr-4">
            <p class="mb-0 text-microcopy--60 font-weight-bold text-uppercase">{{ $tc(campaignType, 1) }}</p>
            <router-link
                class="title"
                tag="a"
                :to="{ name: campaignRouteNameBasedOnType, params: { id: campaign.campaignOfTypeId } }">
                <span>{{ campaign.name }}</span>
                <span class="small text-danger ml-1" v-if="campaign.outcome === 'FAIL'"><i class="fas fa-exclamation-circle"></i></span>
            </router-link>
            <p class="mb-0 text-muted" v-if="campaign.description">{{ campaign.description }}</p>
            <p class="mt-2 mb-0 text-muted text-microcopy--65">{{ $t('audit_created', { dateTime: dateLiteral(campaign.meta.createdDate), actor: campaign.meta.createdBy }) }}</p>
            <p class="mb-0 text-muted text-microcopy--65">{{ $t('audit_updated', { dateTime: dateLiteral(campaign.meta.lastModifiedDate), actor: campaign.meta.lastModifiedBy }) }}</p>
        </div>
        <div class="mt-2 mt-md-0">
            <campaign-status-tag :status="campaign.status"/>
        </div>
    </div>
</template>

<script>
/**
 * Campaign Listing item for Campaigns view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 5 November 2020
 */
export default {
    name: 'CampaignListingItem',
    props: {
        campaign: {
            type: Object,
            default: null,
            required: true
        }
    },
    computed: {
        campaignType () {
            if (this.campaign === null) return null;
            switch (this.campaign.type) {
            case 'EMAIL_CAMPAIGN':
                return 'Email Campaign';
            case 'SMS_CAMPAIGN':
                return 'SMS Campaign';
            default:
                return null;
            }
        },
        campaignRouteNameBasedOnType () {
            if (this.campaign === null) return 'campaigns';
            switch (this.campaign.type) {
            case 'EMAIL_CAMPAIGN':
                return 'email-campaign';
            case 'SMS_CAMPAIGN':
                return 'sms-campaign';
            default:
                return 'campaigns';
            }
        }
    }
};
</script>
