import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import {
    mapFields,
    mapMultiRowFields
} from 'vuex-map-fields';
import {
    ACTION_GET_DATA
} from './genericListing.module';

const defaultMapGettersList = [
    'fetching',
    'data',
    'dataCount',
    'error',
    'displayListing',
    'displayEmptyState',
    'displayError',
    'displayPagination'
];

const defaultMapFieldsList = [
    'listingPage',
    'listingSize'
];

export default function (mixinName = 'Unknown', moduleName = 'none', mapGettersList = defaultMapGettersList, mapGetterObject = {}, mapFieldsList = defaultMapFieldsList, mapFieldsObject = {}, mapMultiRowFieldsList = [], mapMultiRowFieldsObject = {}) {
    return {
        name: 'GenericListingFor' + mixinName + 'Mixin',
        data () {
            return {
                listingSizeAvailableOptions: [
                    {
                        value: 10,
                        selected: true
                    },
                    {
                        value: 20,
                        selected: false
                    },
                    {
                        value: 30,
                        selected: false
                    },
                    {
                        value: 40,
                        selected: false
                    },
                    {
                        value: 50,
                        selected: false
                    }
                ]
            };
        },
        computed: {
            ...mapGetters(moduleName, mapGettersList),
            ...mapGetters(moduleName, mapGetterObject),
            ...mapFields(moduleName, mapFieldsList),
            ...mapFields(moduleName, mapFieldsObject),
            ...mapMultiRowFields(moduleName, mapMultiRowFieldsList),
            ...mapMultiRowFields(moduleName, mapMultiRowFieldsObject),
            listingSizeOptions () {
                let options = cloneDeep(this.listingSizeAvailableOptions);
                for (let item of options) {
                    item.selected = item.value === this.listingSize;
                }
                // @future : Check if one option is selected. Else, set the default.
                return options;
            },
            listingPagePlusOne () {
                return this.listingPage + 1;
            }
        },
        watch: {
            listingPage: {
                handler: debounce(function () {
                    this.getData();
                }, 200),
                deep: true
            },
            listingSize: {
                handler: debounce(function () {
                    this.getData();
                }, 200),
                deep: true
            }
        },
        methods: {
            getData () {
                this.$store.dispatch(moduleName + '/' + ACTION_GET_DATA).then(() => void 0).catch(() => void 0);
            },
            carbonPaginationChange (e) {
                // @future : Validate.
                let start = e.start;
                let size = e.length;
                // noinspection UnnecessaryLocalVariableJS
                let page = (start - 1) / size;
                this.listingPage = page;
                this.listingSize = size;
            },
            bootstrapVuePaginationChange (e) {
                // Bootstrap pagination page count starts from 1.
                // @future : Validate.
                // noinspection UnnecessaryLocalVariableJS
                let page = e - 1;
                this.listingPage = page;
            }
        }
    };
};
