<template>
    <div class="CampaignsView ViewWrapper">
        <clv-head-meta :title="$tc('entity Campaign', 2)"></clv-head-meta>
        <view-header>
            <h1>{{ $tc('entity Campaign', 2) }}</h1>
            <router-link :to="{ name: 'broadcasters' }">{{ $t('Manage Broadcasters') }}</router-link>
        </view-header>
        <view-container>
            <!-- LISTING LOADER -->
            <div class="ElementLoading-Placeholder" v-if="fetching">
                <element-loading :active="true" background-color="transparent"></element-loading>
            </div>

            <!-- EMPTY STATE 2020-07-19 -->
            <simple-state-empty v-if="displayEmptyState">
                <div class="mt-4" slot="action">
                    <div class="mb-2">
                        <router-link tag="button" class="btn btn-accent1" :to="{ query: getGlobalCreatorRouteQuery('emailCampaign') }">{{ $t('Create Email Campaign') }}</router-link>
                    </div>
                    <div>
                        <router-link tag="button" class="btn btn-accent1" :to="{ query: getGlobalCreatorRouteQuery('smsCampaign') }">{{ $t('Create SMS Campaign') }}</router-link>
                    </div>
                </div>
            </simple-state-empty>

            <!-- ERROR STATE 2020-07-19 -->
            <simple-state-error v-if="displayError" :error="error"></simple-state-error>

            <!-- LISTING -->
            <div class="row mt-4" v-if="displayListing">
                <div class="col-12">
                    <campaign-listing-item v-for="(dataItem, dataItemIndex) in data"
                                           :campaign="dataItem"
                                           :index="dataItemIndex"
                                           :key="dataItem.id"></campaign-listing-item>
                </div>
            </div>

            <!-- PAGINATION -->
            <div class="row mt-4" v-show="displayPagination">
                <div class="col-12">
                    <custom-cv-pagination :backward-text="$t('common_pagination_next')"
                                          :forward-text="$t('common_pagination_previous')"
                                          :page-sizes-label="$t('common_pagination_page_size_label', { object: $tc('entity Campaign', 2) })"
                                          :number-of-items="dataCount"
                                          :page="listingPagePlusOne"
                                          :page-sizes="listingSizeOptions"
                                          @change="carbonPaginationChange"></custom-cv-pagination>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import GenericListingMixin from '@/store/generics/GenericListingMixin';
import CampaignListingItem from './CampaignListingItem';

/**
 * Campaigns view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 20 July 2020
 */
export default {
    name: 'CampaignsView',
    components: {
        CampaignListingItem
    },
    mixins: [
        GenericListingMixin('Campaigns', 'campaigns')
    ],
    beforeMount () {
        this.getData();
    }
};
</script>
